import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/mnt/fstack_dev/nykenshinkai.com/nyksk/src/components/default-mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "316px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6066f0610c13da7224d4ed3647415e16/76d8a/ken-kishino.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "152.91666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6066f0610c13da7224d4ed3647415e16/8ac56/ken-kishino.webp 240w", "/static/6066f0610c13da7224d4ed3647415e16/66351/ken-kishino.webp 316w"],
              "sizes": "(max-width: 316px) 100vw, 316px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6066f0610c13da7224d4ed3647415e16/09b79/ken-kishino.jpg 240w", "/static/6066f0610c13da7224d4ed3647415e16/76d8a/ken-kishino.jpg 316w"],
              "sizes": "(max-width: 316px) 100vw, 316px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6066f0610c13da7224d4ed3647415e16/76d8a/ken-kishino.jpg",
              "alt": "Ken Kishino",
              "title": "Ken Kishino",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Rank`}</h2>
    <ul>
      <li parentName="ul">{`Kendo 6th Dan`}</li>
    </ul>
    <h2>{`Background`}</h2>
    <ul>
      <li parentName="ul">{`Born in Kanagawa, Japan.`}</li>
      <li parentName="ul">{`A member of All US Kendo Federation.`}</li>
      <li parentName="ul">{`A member of All Eastern US Kendo Federation.`}</li>
      <li parentName="ul">{`B.A. in Education, at Tokyo Gakugei University.`}</li>
      <li parentName="ul">{`M.A. in Education, at International BUDO University.`}</li>
      <li parentName="ul">{`1985 – A member of Japan-US Kendo Goodwill Envoy.`}</li>
      <li parentName="ul">{`1993 – All Japan High School Championship, Individual 3rd Pl.`}</li>
    </ul>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
  }
  
  .grvsc-code {
    display: inline-block;
    min-width: 100%;
  }
  
  .grvsc-line {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-line-highlighted {
    background-color: var(--grvsc-line-highlighted-background-color, transparent);
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, transparent);
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      